import React from "react"
import Ingress from "../components/index/Ingress"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsIndex from "../components/index/NewsIndex"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Mentis Psykiatri" isHome />
    <div>
      <div className="">
        <NewsIndex />
        <div className="frontpage-content-wrapper">
          <Ingress />
        </div>
      </div>
      {/* <WhyMentis /> */}
    </div>
  </Layout>
)

export default IndexPage
