import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export default function Ingress() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 5 }) {
        startsida {
          ingressStartsida
        }
      }
    }
  `)

  return (
    <div
      id="ingress"
      className=" max-w-4xl mx-auto py-16 md:px-4  lg:px-8  leading-normal"
    >
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.wpPage.startsida.ingressStartsida,
          }}
          className="wp-content"
        />
        {/* <div className="">
          <h3 className="uppercase text-gray-400 text-lg">Vad vi gör</h3>
        </div>
        <div className="text-3xl lg:text-5xl serif  lg:leading-[4rem]">
          <p>
            Här säljer vi in vad som gör er helt unika. Varför man ska välja
            just er. Gärna i en eller två meningar.
          </p>
        </div>
        <div className="text-lg">
          <p>
            Vi utgår från dina utmaningar och var du befinner dig i livet. Det
            är där vi möts och hjälper dig framåt till där du vill vara.
          </p>
        </div> */}
        {/* <div className="spacer"></div> */}
        {/* <div className="text-gray-600 text-2xl">
          <p>Kontakta oss idag för din personliga behandling</p>
        </div> */}
      </div>
    </div>
  )
}
