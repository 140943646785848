import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
export default function NewsIndex({}) {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          title
          uri
          date
        }
      }
    }
  `)

  const posts = data.allWpPost.nodes
  console.log(posts)

  return (
    <>
      <div className="bg-primary">
        <div className="max-w-4xl mx-auto px-4 py-4 md:px-8">
          <div className="flex flex-col md:flex-row justify-between gap-2 items-center">
            <div className="space-x-2">
              <span className="font-bold">Senaste nytt:</span>
              <Link
                to={`${posts[0].uri}`}
                className="underline hover:no-underline"
              >
                {posts[0].title}
              </Link>
            </div>
            <span className="text-sm hidden md:inline-block">
              <Link to={`/nyheter/`}>Visa fler nyheter &rarr;</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className="fill-current  w-4"
  >
    <path
      d="M10 20c5.52 0 10-4.48 10-10S15.52 0 10 0 0 4.48 0 10s4.48 10 10 10zM8.71 4.3l5.7 5.77-5.71 5.65-1.4-1.42 4.29-4.24-4.3-4.35 1.42-1.4z"
      className="cls-1"
    ></path>
  </svg>
)
